.border-none[data-v-3586c91f]:before {
  display: none;
}
.border-none[data-v-3586c91f]:after {
  display: none;
}
.flex-fix[data-v-3586c91f] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-3586c91f]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-3586c91f] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-3586c91f] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-3586c91f] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-3586c91f] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-3586c91f] {
  *zoom: 1;
}
.clear-fix[data-v-3586c91f]:before,
.clear-fix[data-v-3586c91f]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.clickisTrue[data-v-3586c91f] {
  width: 54%;
  position: absolute;
  z-index: 999;
  margin-left: 23%;
  bottom: 13%;
}
.clickSucss[data-v-3586c91f] {
  width: 76%;
  position: absolute;
  height: 15%;
  margin-left: 12%;
  background-image: url("../../assets/WechatIMG14.png");
  background-size: 100% 100%;
  bottom: 10%;
}
.clickSucss p[data-v-3586c91f] {
  width: 80%;
  margin-left: 10%;
  position: absolute;
  bottom: 11%;
  font-size: 0.3rem;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  color: #7b2900;
}
.top1[data-v-3586c91f] {
  width: 100%;
}
.text1[data-v-3586c91f] {
  width: 100%;
  left: 0;
  margin-top: 5%;
}
.bottom1[data-v-3586c91f] {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.lastBottom[data-v-3586c91f] {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.2rem;
  color: #000;
  font-weight: 400;
  background-color: #fff;
  letter-spacing: 2px;
}
.page[data-v-3586c91f] {
  width: 100%;
  height: 100%;
  background: url("../../assets/zhZlbackground.png") repeat-y;
  background-size: 100% 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page .content[data-v-3586c91f] {
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  margin-top: 0.7rem;
}
.page .bgimg[data-v-3586c91f] {
  width: 100%;
  top: 0;
  position: absolute;
}
.page .home-icon[data-v-3586c91f],
.page .collect-icon[data-v-3586c91f] {
  width: 0.56rem;
  height: 0.56rem;
  border-radius: 0.56rem;
  position: absolute;
  z-index: 110;
}
.page .home-icon img[data-v-3586c91f],
.page .collect-icon img[data-v-3586c91f] {
  width: 0.56rem;
  height: 0.56rem;
}
.page .home-icon[data-v-3586c91f] {
  left: 0.3rem;
  top: 0.4rem;
}
.page .collect-icon[data-v-3586c91f] {
  right: 0.3rem;
  top: 0.4rem;
}
