.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.clickisTrue {
  width: 54%;
  position: absolute;
  z-index: 999;
  margin-left: 23%;
  bottom: 13%;
}
.clickSucss {
  width: 76%;
  position: absolute;
  height: 15%;
  margin-left: 12%;
  background-image: url("../../assets/WechatIMG14.png");
  background-size: 100% 100%;
  bottom: 10%;
}
.clickSucss p {
  width: 80%;
  margin-left: 10%;
  position: absolute;
  bottom: 11%;
  font-size: 0.3rem;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  color: #7b2900;
}
.top1 {
  width: 100%;
}
.text1 {
  width: 100%;
  left: 0;
  margin-top: 5%;
}
.bottom1 {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.lastBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.2rem;
  color: #000;
  font-weight: 400;
  background-color: #fff;
  letter-spacing: 2px;
}
.page {
  width: 100%;
  height: 100%;
  background: url("../../assets/zhZlbackground.png") repeat-y;
  background-size: 100% 100%;
  box-sizing: border-box;
}
.page .content {
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  margin-top: 0.7rem;
}
.page .bgimg {
  width: 100%;
  top: 0;
  position: absolute;
}
.page .home-icon,
.page .collect-icon {
  width: 0.56rem;
  height: 0.56rem;
  border-radius: 0.56rem;
  position: absolute;
  z-index: 110;
}
.page .home-icon img,
.page .collect-icon img {
  width: 0.56rem;
  height: 0.56rem;
}
.page .home-icon {
  left: 0.3rem;
  top: 0.4rem;
}
.page .collect-icon {
  right: 0.3rem;
  top: 0.4rem;
}
